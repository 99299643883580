export const API_VERSION = 'v1';

export const API_URL = 'https://panorama.cinemabox.team/api';

export const API_SERVER = 'https://panorama.cinemabox.team';

export const API_PORT = '443';

export const API_PATH = 'api';

export const SOCKET_PORT = '443';
